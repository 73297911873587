<template>
  <div class="inline">
    <a-popover @visibleChange="visibleChange">
      <template slot="title">
        <a-tag color="green" v-if="isWallet && accountClass === '0'">活期A1</a-tag>
        <a-tag color="blue" v-if="isWallet && accountClass === '6'">冻结A2</a-tag>
        <a-tag color="purple" v-if="isWallet && accountClass === '7'">贷款A3</a-tag>
        <a-tag color="yellow" v-if="isWallet && accountClass === '2'">保证A6</a-tag>
        <a-tag color="blue" v-if="isWallet && accountClass === '8'">分户A7</a-tag>
        <a-tag color="cyan" v-if="isWallet && accountClass === '9'">待清算</a-tag>
        {{ walletTitle }}
        <a-button @click="incomeWhitelist" v-if=" isWallet && $authority('incomeWhitelist') " type="link">
          <a-icon type="usergroup-add"/>
        </a-button>
      </template>
      <template slot="content">
        <p v-if="channelName"> 归属渠道：<span>{{ channelName }}</span></p>
        <p v-if="isWallet && showBalance"> 余额：
          <span v-if="isWallet && accountClass === '6' && !$authority('A2BalanceVisible')"
                style="display:inline-block; width:120px;">-----</span>
          <span v-else>
            <a-spin size="small" v-if="displayBalance===undefined"/>
            <Balance v-else style="display:inline-block; " :amount="displayBalance" :align-right="false"
                    :dcflag="accountClass === '7' ? 'c' : ''"></Balance>
          </span>

        </p>
        <p> 户名：<span>{{ accountName }}</span>
          <a-icon type="copy" v-clipboard:copy="accountName" v-clipboard:success="onCopy"
                  v-clipboard:error="onCopyErr"/>
        </p>
        <p> 账号：<span>{{ accountNo }}</span>
          <a-icon type="copy" v-clipboard:copy="accountNo" v-clipboard:success="onCopy" v-clipboard:error="onCopyErr"/>
        </p>
        <p v-if="showBank">
          开户银行：<span>{{ displayBankInfo?.bankName || '' }}</span>
          <a-icon type="copy" v-clipboard:copy="displayBankInfo?.bankName || ''" v-clipboard:success="onCopy" v-clipboard:error="onCopyErr"/>
        </p>
        <p v-if="showBank">
          大额行号：<span>{{ displayBankInfo?.bankNo || '' }}</span>
          <a-icon type="copy" v-clipboard:copy="displayBankInfo?.bankNo || ''" v-clipboard:success="onCopy" v-clipboard:error="onCopyErr"/>
        </p>




      </template>
      <div v-if="isHandleBtn" class="account_no">
        <a-tag color="pink" v-if="!isWallet">银行卡</a-tag>
        <a-tag color="green" v-else-if="isWallet && accountClass === '0'">活期A1</a-tag>
        <a-tag color="blue" v-else-if="isWallet && accountClass === '6'">冻结A2</a-tag>
        <a-tag color="geekblue" v-else-if="isWallet && accountClass === '8'">分户A7</a-tag>
        <a-tag color="purple" v-else-if="isWallet && accountClass === '7'">贷款A3</a-tag>
        <a-tag color="yellow" v-else-if="isWallet && accountClass === '2'">保证A6</a-tag>
        <a-tag color="cyan" v-else-if="isWallet && accountClass === '9'">待清算</a-tag>
        <a-tag color="blue" v-else>钱包</a-tag>
        <span v-if="showAccountNo" class="account_no">{{ accountNo }}</span>
        <span v-if="showAccountName" class="account_no">&nbsp;&nbsp;{{ accountName }}</span>
        <template v-if="showBalance && balance !== undefined">
          <span v-if="isWallet && accountClass === '6' && !$authority('A2BalanceVisible')"
                style="display:inline-block; width:120px;">-----</span>
          <Balance v-else style="display:inline-block; width:120px;" :amount="displayBalance"
                   :dcflag="this.accountClass === '7' ? 'c' : ''"></Balance>
        </template>
      </div>
      <div v-else>
        <a-button type="link" @click="clickBtn">
          {{ handleTitle() }}
        </a-button>
      </div>

    </a-popover>

    <!-- 保证金缴纳弹层  和保证金释放-->
    <a-modal style="width: 500px;" :maskClosable="true" :title="Securitydeposittype == 0 ? '保证金缴纳' : '保证金释放'"
             :centered="true" :visible="Securitydepositvisible" @cancel="Securitydepositvisible = false">
      <!-- 保证金缴纳 -->
      <div v-if="Securitydeposittype == 0" class="Securitydeposit">
        <!-- 用户信息缴纳 -->
        <div v-if="Securitydeposittype == 0" class="userinfo">
          <!-- 账号 -->
          <div class="Account number">
            <span> 账号： </span>
            <span>{{ accountNoDb }}</span>
          </div>
          <!-- 户名 -->
          <div class="username">
            <span> 户名： </span>
            <span> {{ dbAccountInfo.account_name }}</span>
          </div>
          <!-- 账户余额 -->
          <div class="blane">
            <span> 账户余额（元）： </span>
            <span>
              <Balance :amount="currentBalance"></Balance>
            </span>
          </div>
        </div>

        <!-- 缴纳金额 -->
        <div class="Payoverbox">
          <div class="left-title">缴纳金额：</div>
          <a-input type="number" v-model.trim="Payovernum" class="Payover" suffix="元">

          </a-input>
        </div>
        <!--短信验证码-->
        <div class="codebox">
          <div class="left-title">短信验证码：</div>
          <a-input v-model.trim="Payovercode" class="code"></a-input>
          <CountdownBtn :wait-time="60" :error-wait-time="10" :send-call="sendcode">
          </CountdownBtn>

        </div>
        <div class="codebox">
          <div class="left-title">验证码手机:</div>
          <div>{{ dbAccountInfo.mobile }}</div>
        </div>
      </div>
      <!-- 保证金释放 -->
      <div v-else class="SecuritydepositRelease">
        <!-- 释放 -->
        <div class="userinfo">
          <!-- 账号 -->
          <div class="Account number">
            <span> 保证金账号： </span>
            <span>{{
                accountNoDb
              }}</span>
          </div>
          <!-- 户名 -->
          <div class="username">
            <span> 保证金用名户名： </span>
            <span> {{ dbAccountInfo.account_name }}</span>
          </div>
          <!-- 金额 -->
          <div class="money">
            <span> 保证金金额（元）： </span>
            <span>
              <Balance :amount="marginBalance"></Balance>
            </span>
          </div>

          <!-- AI账户 -->
          <div class="aiaccount">
            <span>A1账户:</span>
            <span> {{ accountNoDb }}</span>
          </div>
        </div>

        <!-- 释放金额 -->
        <div class="Payoverbox">
          <div class="left-title">释放金额</div>
          <a-input type="number" v-model.trim="Releaseamount" class="Payover">
            <template #suffix>
              <div class="yaun">元</div>
            </template>
          </a-input>
        </div>
        <!--短信验证码-->
        <div class="codebox">
          <div class="left-title">短信验证码：</div>
          <a-input v-model.trim="Releasecode" class="code"></a-input>
          <CountdownBtn :wait-time="60" :error-wait-time="10" :send-call="sendcode">
          </CountdownBtn>

        </div>
        <div class="codebox">
          <div class="left-title">验证码手机:</div>
          <div>{{ dbAccountInfo.mobile }}</div>
        </div>
      </div>
      <!-- 缴纳 -->
      <template v-slot:footer>
        <div v-if="Securitydeposittype == 0" class="custom-footer">
          <a-button @click="Securitydepositvisible = false">取消</a-button>
          <a-button type="primary" @click="Confirmationofpayment">缴纳</a-button>
        </div>
        <!-- 释放 -->
        <div v-else class="custom-footer">
          <a-button @click="Securitydepositvisible = false">取消</a-button>
          <a-button type="primary" @click="Release">释放</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 提现弹窗 -->
    <a-modal :maskClosable="true" title="提现" :centered="true" :visible="withdrawvisible"
             @cancel="withdrawvisible = false">
      <!-- 提现 -->
      <div class="Confirmedwithdrawal">
        <div class="userinfo">
          <!-- 付款账号 -->
          <div class="Accountnumber">
            <span>
              付款账号：
            </span>
            <span>{{ accountNo }}</span>
          </div>
          <!-- 户名 -->
          <div class="username">
            <span>
              付款户名：
            </span>
            <span> {{ accountName }}</span>
          </div>
          <!-- 金额 -->
          <div class="money">
            <span>
              可提现金额（元）：
            </span>
            <span>
              <Balance :amount="withdrawBalance"></Balance>

            </span>
          </div>

          <!-- 手机号 -->
          <div class="aiaccount">
            <span>手机号:</span>
            <span> {{ dbAccountInfo.mobile }}</span>
          </div>
          <!--  提现手续费-->
          <div class="Handlingcharge">
            <span>提现手续费:</span>
            <span> 0</span>
          </div>
        </div>

        <!-- 银行卡号 -->
        <div class="Bankcard">
          <div class="left-title">银行卡号 :</div>
          <a-select v-model.trim="Bankcardnumber" placeholder="请选择银行卡号" @change="Bankcardchange"
                    style="width: 220px">
            <a-select-option v-for="(item, ind) in Bankcardlist" :key="ind" :value="item.id">
              {{ item.bankCardNo }}
            </a-select-option>

          </a-select>
        </div>

        <!-- 开户银行 -->
        <div class="Bankaccountopening">
          <div class="left-title">开户银行 :</div>
          <div>
            <a-input :disabled="true" v-model.trim="Bankcardname" style="width: 220px"></a-input>
          </div>
        </div>
        <!-- 提现金额 -->
        <div class="Payoverbox">
          <div class="left-title">
            提现金额 :
          </div>
          <a-input type="number" v-model.trim="withdrawamount" class="Payover" suffix="元">
          </a-input>
        </div>

        <!--短信验证码-->

        <div class="codebox">
          <div class="left-title">短信验证码 ：</div>
          <a-input v-model.trim="withdrawcode" class="code"></a-input>
          <CountdownBtn :wait-time="60" :error-wait-time="10" :send-call="withDrawalSendcode">
          </CountdownBtn>
        </div>
      </div>
      <template v-slot:footer>
        <!-- 提现 -->
        <div class="custom-footer">
          <a-button @click="withdrawvisible = false">取消</a-button>
          <a-button type="primary" @click="Confirmedwithdrawal">提现</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 来账白名单 -->
    <a-modal :maskClosable="true" title="来账白名单" :centered="true" :visible="whiteListVisible">
      <p>入账户名：{{ accountName }} </p>
      <p>入账账号：{{ accountNo }}</p>


      <p>
        小额认证：
        <span v-if="smallAmountAuth  " style=" color: green;">已认证</span>

        <span v-if="!smallAmountAuth && !legalRepAuth" style=" color:red;"> 未认证</span>
        <span v-if="!smallAmountAuth && legalRepAuth"
              style=" color:orange;"> 同名户打款未认证，法定代表人打款已认证</span>

        <a-button type="link" v-if="!smallAmountAuth" icon="reload" @click="handleSmallAmountAuth"/>


      </p>


      <el-select v-model="whiteList" multiple placeholder="请选择" allow-create filterable>
        <el-option-group
            v-for="group in whiteListOptions"
            :key="group.label"
            :label="group.label"
        >
          <el-option
              v-for="(item,index) in group.accounts"
              :key="group.label + index"
              :value="item"
          >
          </el-option>
        </el-option-group>
      </el-select>
      <br/>
      <a-button type="link" @click="whiteListSelectAll">全部选中</a-button>
      <a-button type="link" @click="whiteListDisSelectAll">全部取消</a-button>
      <a-button type="link" v-if="accountProperty === '2' " @click="getLegalPerson">填入法定代表人</a-button>


      <template v-slot:footer>
        <!-- 来账白名单 -->
        <div class="custom-footer">
          <a-button @click="whiteListVisible = false">取消</a-button>
          <a-button @click="confirmWhiteList" type="primary">配置</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import Balance from "@/view/components/Balance.vue";
import CountdownBtn from "@/view/components/CountdownBtn.vue";

export default {
  name: "WalletInfo",
  components: {Balance, CountdownBtn},
  props: {
    isWallet: {
      required: false, // 是否电子钱包， false表示行外账号
      type: Boolean,
      default: true,
    },
    accountName: String, // 户名
    accountNo: {
      // 账号
      required: true,
      type: String,
    },
    showBank: {
      required: false,
      type: Boolean,
      default: true,
    },
    bankName: {
      // 开户行名称
      type: String,
      // default: "浙江稠州商业银行股份有限公司",
    },
    bankNo: {
      // 开户行大额行号
      type: String,
      // default: "313338707013",
    },
    balance: Number, // 余额
    showBalance: { // 显示钱包余额
      type: Boolean,
      default: true
    },
    accountClass: String, // 0-活期 2-保证 6-冻结 7-贷款
    accountProperty: String, // 1-个人， 2-对公
    channelName: String, // 渠道
    showAccountNo: {
      type: Boolean,
      default: true,
    },
    num: {
      type: Number,
      default: 0,
    },
    isTable: {
      type: Boolean,
      default: false,
    },
    showAccountName: {
      type: Boolean,
      default: false,
    },
    handleType: String,//1充值2提现3缴纳4释放
    isHandleBtn: {
      type: Boolean,
      default: true,
    },
    channelNo: String,
    appNo: String,
    selectDbInfo: Object, //父组件传来的担保人信息
    idNo: String,
  },
  data() {
    return {
      // 担保人活期账号
      accountNoDb: '',
      currentBalance: 0,//活期余额
      marginBalance: 0,
      // 保证金弹窗类型 0 缴纳 1 释放
      Securitydeposittype: '',
      // 控制保证金弹窗
      Securitydepositvisible: false,
      // 缴纳金额
      Payovernum: '',
      // 短信验证码
      Payovercode: '',
      Countdown: 60,
      // 释放金额
      Releaseamount: '',
      // 释放验证码
      Releasecode: "",
      // 
      dbAccountInfo: {},
      withdrawvisible: false,
      withdrawBalance: 0,//可提现金额
      withdrawamount: 0,//提现金额
      withdrawcode: '',
      Bankcardlist: [],
      Bankcardnumber: '',//银行卡号
      Bankcardname: '',//银行名称
      whiteListOptions: this.$store.state.incomingWhitelistAccounts, // 来账白名单选项
      whiteList: [], // 来账白名单选中明细
      configWhiteList: [], // 来账白名单选中明细
      whiteListVisible: false, // 是否显示来账白名单
      smallAmountAuth: false, // 是否通过小额认证
      legalRepAuth: false, // 是否通过法人打款认证
      corporateAuth: false, // 是否通过公户打款认证
      coreCompanyList: [],
      balanceUpdated: undefined,
    };
  },
  created() {
    this.coreCompanyList = JSON.parse(this.$session.getItem("coreCompanyList"));
  },
  computed: {

    walletTitle() {
      let title = "";
      title += this.accountProperty === "1" ? "个人" : "";
      title += this.accountProperty === "2" ? "对公" : "";
      title += this.isWallet ? "钱包" : "账户";
      // title += "信息"
      return title;
    },
    handleTitle() {
      return function () {
        if (this.handleType === '1') {
          return '充值'
        } else if (this.handleType === '2') {
          return '提现'
        } else if (this.handleType === '3') {
          return '缴纳'
        } else if (this.handleType === '4') {
          return '释放'
        }
      }

    },
    displayBalance(){
      return this.balanceUpdated?? this.balance
    },
    displayBankInfo() {
      let channelNo = this.channelNo || this.accountNo.substring(0, 4)
      if (this.isWallet) {
        let coreInfo = this.coreCompanyList.find(item => item.channelNo === channelNo) ?? {}
        return {
          bankName: coreInfo?.czcbBankName || "浙江稠州商业银行股份有限公司",
          bankNo: coreInfo?.czcbBankNo || "313338707013",
        };
      }

      return {
        bankName: this.bankName,
        bankNo: this.bankNo,
      }
    }

  },
  methods: {
    onCopy() {
      this.$message.info("已复制");
    },
    onCopyErr() {
      this.$message.info("复制失败");
    },
    // 操作按钮
    clickBtn() {
      if (!this.accountNo) {
        return this.$message.warning('请先选择账户!')
      }
      if (this.handleType === '2') {
        // 提现
        this.withdraw()
        this.dbAccountInfo = this.selectDbInfo
        this.dbAccountInfo.detail_list.forEach(item => {
          if (item.account_class === '0') {
            this.accountNoDb = item.account_no
            this.withdrawBalance = item.withdraw_balance
          } else if (item.account_class === '2') {
            this.marginBalance = item.cur_balance
          }
        })
        this.withdrawvisible = true
      } else if (this.handleType === '3') {
        // 保证金缴纳
        this.Securitydeposittype = 0
        // 打开弹框
        this.Securitydepositvisible = true
        this.dbAccountInfo = this.selectDbInfo
        this.dbAccountInfo.detail_list.forEach(item => {
          if (item.account_class === '0') {
            this.accountNoDb = item.account_no
            this.currentBalance = item.cur_balance
          } else if (item.account_class === '2') {
            this.marginBalance = item.cur_balance
          }
        })

      } else if (this.handleType === '4') {
        // 保证金释放
        this.Securitydeposittype = 1
        // 打开弹框
        this.Securitydepositvisible = true
        this.dbAccountInfo = this.selectDbInfo
        this.dbAccountInfo.detail_list.forEach(item => {
          if (item.account_class === '0') {
            this.accountNoDb = item.account_no
          } else if (item.account_class === '2') {
            this.marginBalance = item.cur_balance
          }
        })
      }
    },
    // 发送验证码
    async sendcode() {
      var data = {
        appNo: Number(this.appNo),
        channelNo: Number(this.channelNo),
        mobile: Number(this.dbAccountInfo.mobile),
        tradeType: 9,
        modelNo: '',
      }
      // 缴纳  102010  释放  102011
      if (this.Securitydeposittype == 0) {
        data.modelNo = '102010'
      } else {
        data.modelNo = '102011'
      }

      const res = await this.api.vefifycodesend(data)

      if (res.code == 200) {
        this.$message.success('验证码发送成功')

      } else {
        this.$message.error(res.msg)
      }
    },
    // 保证金确认缴纳函数
    async Confirmationofpayment() {
      if (this.Payovernum == '' || this.Payovercode == '') {
        this.$message.warning('请先输入缴纳金额和验证码')
        return
      }
      // 校验验证码,金额
      if (this.Payovernum !== '' && this.Payovercode !== '') {
        // 验证码校验
        let data = {
          channel_no: this.channelNo,
          app_no: this.appNo,
          account_no: this.accountNoDb,
          account_name: this.dbAccountInfo.account_name,
          trade_amount: Number(this.Payovernum),
          verify_code: this.Payovercode,
          remark: '',
        }
        const res = await this.api.bailbailInsureApi(data)
        if (res.code !== 200) {
          this.$message.error(res.info)
          return
        }
        this.Payovernum = ''
        this.Payovercode = ''
        this.Securitydepositvisible = false
        this.$message.success('保证金缴纳成功')
      }
    },
    // 释放
    async Release() {
      if (this.Releaseamount == '' || this.Releasecode == '') {
        this.$message.warning('请先输入释放金额和验证码')
        return
      }

      // 校验验证码,金额
      if (this.Releaseamount !== '' && this.Releasecode !== '') {
        let data = {
          channel_no: this.channelNo,
          app_no: this.appNo,
          account_no: this.accountNoDb,
          account_name: this.accountName,
          trade_amount: Number(this.Releaseamount),
          verify_code: this.Releasecode,
          remark: '',
        }
        const res = await this.api.bailRepayApi(data)

        if (res.code !== 200) {
          this.$message.error(res.info)
          return
        }

        this.$message.success('保证金释放成功')
        this.Securitydepositvisible = false
        this.Releasecode = ''
        this.Releaseamount = ''

      }
    },
    // 提现函数
    async withdraw() {
      const res = await this.api.bankcardpageApi(0, 100000, {
        accountNo: this.accountNo
      })

      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      // res.data.records.forEach((item) => {
      //   this.Bankcardlist.push({
      //     label: item.bankCardNo,
      //     value: item.bankCardNo,
      //     bankName: item.bankName,
      //   })
      // })
      this.Bankcardlist = res.data.records
    },
    // 选择名称
    Bankcardchange(value) {
      this.Bankcardlist.forEach((item) => {
        if (item.id == value) {

          this.Bankcardname = item.bankName
          this.Bankcardnumber = item.bankCardNo
        }
      })
    },
    //提现 发送验证码
    async withDrawalSendcode(type) {
      // 1 释放 ， 提现 2
      return
      let data = {
        appNo: this.appNo,
        channelNo: this.channelNo,
        mobile: this.dbAccountInfo.mobile,
        accountNo: this.accountNo,
        cardNo: this.Bankcardnumber,
        accountName: this.accountName,
        amount: this.withdrawamount,
        idNo: this.idNo,
        tradeType: '3',
      }

      const res = await this.api.vefifycodesend(data)

      if (res.code == 200) {

        this.$message.success('验证码发送成功');


      } else {
        this.$message.error(res.msg);
      }

    },
    // 确认提现
    async Confirmedwithdrawal() {

      // 校验卡号，金额，验证码

      if (this.withdrawcode == '' || this.withdrawamount == '' || this.Bankcardnumber == '') {
        this.$message.warning('请先选择银行卡并且输入提现金额和验证码');
        return
      }
      // 校验验证码,金额
      if (
          this.withdrawcode !== '' &&
          this.withdrawamount !== '' &&
          this.Bankcardnumber !== ''
      ) {
        // 发送提现请求
        let data = {
          channel_no: this.channelNo,
          app_no: this.appNo,
          account_no: this.accountNo,
          account_name: this.accountName,
          primary_account: this.Bankcardnumber,
          amount: this.withdrawamount,
          verify_code: this.withdrawcode,
        }
        const res = await this.api.merWithdrawApi(data)
        if (res.code !== 200) {
          this.$message.error(res.info)
          return
        }
        this.$message.success('提现成功')
        this.withdrawvisible = false
      }
    },


    async handleSmallAmountAuth() {
      const rsp = await this.api.smallAmountAuthApi(this.accountNo);
      if (rsp.code !== 200) {
        this.$message.error(rsp.info)
        await this.handleLegalRepAuth();
      } else {
        if (!rsp.data) {
          this.$message.error("小额打款认证失败")
          await this.handleLegalRepAuth();
        }
      }
      this.getAmountAuthApi();
    },

    async handleLegalRepAuth() {
      await this.api.legalPersonAuthApi(this.accountNo);

    },


    // 打开来账白名单配置界面
    async incomeWhitelist() {
      this.configWhiteList = []
      // 从后台获取来账白名单清单
      let info = await this.api.bmdListApi(this.accountNo);
      if (info.code === 200) {
        this.whiteList = [...info.data];
        this.configWhiteList = [...info.data];
      } else {
        this.whiteList = []
        this.configWhiteList = []
      }
      //查看是否小额认证通过
      this.getAmountAuthApi();

      this.whiteListVisible = true
    },

    async getAmountAuthApi() {
      const rsp = await this.api.getAmountAuthApi(this.accountNo);
      if (rsp.code === 200) {
        this.smallAmountAuth = rsp.data.smallAmountAuth
        this.legalRepAuth = rsp.data.legalRepAuth
        this.corporateAuth = rsp.data.corporateAuth
        this.accountProperty = rsp.data.accountProperty
      } else {
        this.smallAmountAuth = false
        this.legalRepAuth = false
        this.corporateAuth = false
      }
    },

    whiteListSelectAll() {
      this.whiteList = [...new Set(this.whiteListOptions.flatMap(item => item.accounts))]
    },
    whiteListDisSelectAll() {
      this.whiteList = []
    },

    async confirmWhiteList() {
      console.info("this.whiteList", this.whiteList)
      console.info("this.configWhiteList", this.configWhiteList)
      // 去除已经配置的名单
      this.whiteList = this.whiteList.filter(item => !this.configWhiteList.includes(item))
      const res = await this.api.bmdAddApi({
        accountNo: this.accountNo,
        oppAccountName: this.whiteList
      });
      if (res.code === 200) {
        this.$msgbox({
          type: "info",
          customClass: 'msg-box-wide',
          message: <a-descriptions title="白名单配置结果" bordered>
            <a-descriptions-item label="成功账户" span="3">
              {res.data.succeed}
            </a-descriptions-item>
            <a-descriptions-item label="失败账户" span="3">
              {res.data.fail}
            </a-descriptions-item>

          </a-descriptions>,
          callback: () => {
          }
        })
        this.whiteListVisible = false;


      } else {
        this.$message.error(res.info);
      }

      this.whiteListVisible = false

    },

    async getLegalPerson() {
      let res = await this.api.getLegalPerson(this.accountNo)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.whiteList.push(res.data.custname)
      this.whiteList = [...new Set(this.whiteList)]
    },


    async reloadBalance() {
      if(!this.isWallet || this.displayBalance!==undefined)
        return
      if(!this.showBalance)
        return
      this.balanceUpdated = undefined
      let res = await this.api.accountqueryBalancedApi({
        channel_no: this.channelNo,
        account_no: this.accountNo
      }, true)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.balanceUpdated = res.data.detail_list[0].avail_balance
    },
    visibleChange(visible){
      if(visible){
        window.setTimeout(this.reloadBalance, 200)
      }
    }
  },
};
</script>
<style scoped lang="less">
i,
.account_no {
  cursor: pointer;
}

.ant-tag {
  margin: 0;
}

// 保证金
.Securitydeposit,
.SecuritydepositRelease {
  padding-bottom: 20px;

  .userinfo {
    padding: 20px;
    background-color: #f5f8ff;
    min-height: 130px;

    div {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .blane {
      margin-bottom: 0px;
      display: flex;
    }

    .money {
      display: flex;
    }

    margin-bottom: 30px;
  }

  .Payoverbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;
    margin-bottom: 20px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }

    /deep/ .Payover {
      .ant-input {
        position: relative;
        width: 220px;
        padding-right: 0px !important;
      }

      .ant-input-suffix {
        position: absolute;
        width: 40px;
        right: 95px;
        top: 0;
        margin-top: 16px;
        margin-left: 20px;
        height: 32px;
        background-color: #f2f2f2;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .codebox {
    display: flex;
    padding-right: 0px;
    // justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    width: 100%;
    padding-left: 25px;
    margin-bottom: 25px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }

    .code {
      width: 110px;
      margin-right: 10px;
    }
  }
}

.Confirmedwithdrawal {
  padding-bottom: 20px;

  .userinfo {
    padding: 20px;
    background-color: #f5f8ff;
    min-height: 130px;


    div {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .money {
      display: flex;
    }

    .blane {
      margin-bottom: 0px;
    }

    margin-bottom: 30px;
  }

  .Payoverbox {
    display: flex;
    padding-right: 0px;
    // justify-content: space-between;
    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;
    margin-bottom: 20px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }


  }

  .codebox {
    display: flex;
    display: flex;
    padding-right: 0px;

    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }

    input {
      margin-right: 10px;
    }

    .code {
      width: 110px;

      // margin-right: 10px;

    }
  }
}

.Confirmedwithdrawal {
  .Bankcard {
    display: flex;
    display: flex;
    padding-right: 0px;

    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;
    margin-bottom: 20px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }
  }

  .Bankaccountopening {
    display: flex;
    display: flex;
    padding-right: 0px;
    // justify-content: space-between;
    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;
    margin-bottom: 20px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }
  }
}

.account-info-table {
  display: table;
  width: 100%;
  border-spacing: 0; /* 去除表格的单元格间距 */
  border-collapse: collapse; /* 边框重叠处理 */
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  padding: 8px; /* 调整单元格内边距 */
  border-bottom: 1px solid #ccc; /* 添加底部边框 */
  vertical-align: middle; /* 垂直居中对齐 */
  text-align: center; /* 文本居中对齐 */
}

.table-cell:last-child {
  border-right: none; /* 最后一列去除右侧边框 */
}

.table-cell:first-child {
  border-left: none; /* 第一列去除左侧边框 */
}

div.inline {
  display: inline-block;
}

</style>